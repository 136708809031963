@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 6px; 
}

::-webkit-scrollbar-thumb {
  background: #e75113;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@font-face {
  font-family: 'serifReclare';
  src: url('./fonts/ReciaSerifDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'inter-normal';
  src: url('./fonts/Inter/static/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.dark body {
  background-color: #1a1a1a;
}

body {
  margin: 0;
  font-family: inter-normal;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.tooltip {
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #e75113;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 20%;
  left: 180%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@layer utilities {
  .animation-delay-200 {
    animation-delay: 0.2s;
  }
  .animation-delay-400 {
    animation-delay: 0.4s;
  }
  .animation-delay-600 {
    animation-delay: 0.6s;
  }
  .animation-delay-800 {
    animation-delay: 0.8s;
  }
  .animation-delay-1000 {
    animation-delay: 1s;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background: linear-gradient(to right, #222222, #fff, #222222);
  background-size: 200% auto;
  animation: gradient 3s linear infinite;
  -webkit-background-clip: text;
  background-clip: text;
}

.progress-bar {
  transition: width 0.3s ease-in-out;
}
